<template>
    <div class="message alert alert-success d-flex justify-content-between mt-3">
        <span>{{ message }}</span>
        <i @click="$emit('unshow-success-message')" class="bi bi-x-lg close-icon"></i>
    </div>
</template>

<script>

export default {
    name: 'SuccessMessageView',
    props: {
        message: String
    },
    data(){
        return {

        }
    }
}

</script>

<style>

.alert{
    width: 90%;
}

.close-icon{
    cursor: pointer;
}

</style>