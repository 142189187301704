<template>
    <div class="alert alert-danger d-flex justify-content-between mt-3">
        <span>
            <span class="message">{{ alert_message }}</span>
        </span>
        <i @click="$emit('unshow-client-nif-error')" class="bi bi-x-lg close-icon"></i>
    </div>
</template>


<script>

export default {
    name: 'AlertMessageView',
    props: {
        alert_message: String,
    }
}
</script>


<style>

.alert{
    width: 100%;
    align-items: center;
    gap: .5rem;
}

.message{
    font-weight: 600;
}

.see-debts{
    color: inherit;
}

.close-icon{
    cursor: pointer;
}

</style>