<template>
    <div class="alert alert-danger d-flex justify-content-between mt-3">
        <span>
            <span class="message">Você possui dívidas.</span>
            <router-link class="see-debts" to="/validation"> Ver dívidas</router-link>
        </span>
        <i @click="$emit('unshow-debt-message')" class="bi bi-x-lg close-icon"></i>
    </div>
</template>

<script>

export default {
    name: 'DebtMessageView',
    data() {
        return {

        }
    }
}

</script>

<style>

.alert{
    width: 90%;
}

.message{
    font-weight: 600;
}

.see-debts{
    color: inherit;
}

.close-icon{
    cursor: pointer;
}


</style>