<template>
    <div class="form-container">
        <div class="form-box">
            <p class="form-text-subtitle">Pague dívidas com negociações amigáveis</p>
            <h1 class="form-title">Negocie as suas dívidas conosco</h1>
            <p class="form-text-description">Consulte o seu NIF para saber se possui dívidas</p>
            <form class="form" @submit.prevent="validateClientNif">
                <input v-model="client_nif" type="text" name="consultnif" id="consultnif" class="form-control form-input" placeholder="Insira aqui o seu NIF">
                <button type="submit" class="btn btn-form">
                    <div v-if="is_loading" class="text-center">
                        <div class="spinner-border" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                    </div>
                    <span v-else>Consultar</span>
                </button>
            </form>
            <!-- DEBT AND NO_DEBT MESSAGE -->
            <Success-Message-View @unshow-success-message="unshowNoDebtMessage" v-if="no_debt_message" :message="no_debt_message" :class="_no_debt_animation"/>
            <Debt-Message-View @unshow-debt-message="unshowDebtMessage" v-if="debt_message" :class="_debt_animation" />
            <!-- NIF ERROR MESSAGE -->
            <Alert-Message-View :alert_message="alert_message" v-if="alert_message" @unshow-client-nif-error="unshowClientNifError" :class="_alert_animation"/>

        </div>
    </div>
</template>

<script>

import SuccessMessageView from '../complement/SuccessMessageView.vue';
import DebtMessageView from '../complement/DebtMessageView.vue';
import AlertMessageView from '../complement/AlertMessageView.vue';
import api from '@/services/axios.js';
import { mapActions } from 'vuex';

export default {
    name: 'CheckCitizenIdView',
    components: {
        SuccessMessageView,
        DebtMessageView,
        AlertMessageView
    },
    data () {
        return {
            alert_message:null,
            no_debt_message:null,
            debt_message:false,
            client_nif:null,
            is_loading:false,
            _no_debt_animation: "animate__animated animate__fadeIn animate__faster",
            _debt_animation: "animate__animated animate__shakeX animate__faster",
            _alert_animation: "animate__animated animate__shakeX animate__faster",
        }
    
    },
    methods: {
        ...mapActions(['setClientNif']),
        clearMessages(){
            if(this.no_debt_message) this.no_debt_message = null;
            if(this.debt_message) this.debt_message = false;
            if(this.alert_message) this.alert_message = null;
        },
        unshowNoDebtMessage(){
            this._no_debt_animation = "animate__animated animate__fadeOut animate__faster";
            setTimeout(()=> {
                this.no_debt_message = null;
                this.client_nif = null;
                this._no_debt_animation = "animate__animated animate__fadeIn animate__faster";
            },750);
        },
        unshowDebtMessage(){
            this._debt_animation = "animate__animated animate__fadeOut animate__faster";
            setTimeout(()=> {
                this.debt_message = false;
                this.client_nif = null;
                this._debt_animation = "animate__animated animate__shakeX animate__faster";
            },750);
        },
        unshowClientNifError(){
            this._alert_animation = "animate__animated animate__fadeOut animate__faster";
            setTimeout(()=> {
                this.alert_message = null;
                this.client_nif = null;
                this._alert_animation = "animate__animated animate__shakeX animate__faster";
            },750);
        },
        async debtorCheck(){
            this.is_loading = true;
            try {
                const response = await api.post('regulariza', 
                {
                    "service": "check_debts",
                    "debtor_nif": this.client_nif
                });

                const data = response.data;

                switch(data.message) {
                    case "NIF_WAS_FOUND_WITH_DEBTS":
                        this.debt_message = true;
                        this.setClientNif(this.client_nif);
                        break;
                    case "NIF_WAS_FOUND_WITH_NO_DEBTS":
                        this.no_debt_message = "Você não possui dívidas";
                        break
                    case "NIF_WAS_NOT_FOUND":
                        this.alert_message = "NIF não encontrado";
                        break
                    default:
                        this.alert_message = "Ocorreu um erro, por favor tente mais tarde";
                        break
                }

            } catch (error) {
                switch(error.response.data.message) {
                    case "MISSING_REQUEST_ATTRIBUTE":
                        this.alert_message = "Valor inválido, verifique e tente novamente";
                        break
                    default: 
                        this.alert_message = "Ocorreu um erro, por favor tente mais tarde";
                        break
                }
            } finally{
                this.is_loading = false;
            }


        },
        validateClientNif(){
            const personal_nif_regex = /^[0-9A-Z]+$/;
            const business_nif_regex = /^\d+$/;

            if(this.client_nif == "" || this.client_nif == null) {
                this.clearMessages();
                setTimeout(() => {
                    this.alert_message = 'O campo NIF é obrigatório';
                }, 2);

            } else {
                const validate_personal_nif = this.client_nif.trim().length == 14 && personal_nif_regex.test(this.client_nif);
                const validate_business_nif = this.client_nif.trim().length == 10 && business_nif_regex.test(this.client_nif);

                if(validate_personal_nif || validate_business_nif) {
                    this.clearMessages();
                    this.debtorCheck();
                }else {
                    this.clearMessages();
                    this.alert_message = 'O NIF informado é inválido';
                }
            }

        },
    },

}

</script>


<style scoped>

.form-container{
    height: 100%;
    width: 100%;
}
.form-box{
    width: 100%;
}

.form-text-subtitle{
    font-weight: 600;
    text-transform: uppercase;
    font-size: 1.63rem;
    color: #666666;
    margin-bottom: 1.5rem;
}
.form-title{
    font-size: 3.55rem;
    font-weight: 600;
    color: #3D1152;
    font-weight: 700;
}

.form-text-description{
    color: #666666;
    margin-top: 0.5rem;
    font-size: 1.125rem;
}

.form{
    width: 90%;
    position: relative;
    margin-top: 3rem;
}

.form-input{
    border: solid 1px #3D1152;
    box-shadow: none !important;
    padding: 16px 0 16px 16px;
    border-radius: 5px !important;
    color: #666666;
}

.form-input:focus{
    border-color: #3D1152;
    color: #666666;
}

.btn-form{
    background-color: #3D1152;
    position: absolute;
    top: 6px;
    right: 6px;
    color: #ffffff;
    font-weight: 600;
    width: 6.6rem;
    height: 2.85rem;
    border-radius: 4px;
    transition: all 0.5s;
}

.btn-form:hover{
    background-color: #1ECAD4;
    color: #ffffff;
}

.spinner-border{
    width: 20px;
    height: 20px;
}

.alert{
    width: 90%;
}

@media (max-width:992px) {
    .form-text-subtitle{
        font-size: 1.2rem;
    }
    .form-title{
        font-size: 2.8rem;
    }
    .form-box{
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
    }
    .form{
        width: 100%;
    }
    .alert{
        width: 100%;
    }
}

@media (max-width:576px) {
    .form-text-subtitle{
        font-size: 1rem;
    }
    .form-title{
        font-size: 2rem;
    }
    .form-text-description{
        font-size: .9rem;
    }
    .form-box{
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
    }
    .form{
        width: 100%;
    }

    .form-input::placeholder{
        font-size: .9rem;
    }

    .btn-form{
        font-size: .83rem;
        width: 6rem;
    }
}

</style>