import { createStore } from 'vuex'
import CryptoJS from 'crypto-js';
import Cookies from 'js-cookie'

/* DATA ENCRYPTION AND DECRYPTION PROCESS */
const secretKey = ';Az.>6.qt<bjg+U67sW)^04Zj';

function encryptData(data) {
  return CryptoJS.AES.encrypt(JSON.stringify(data), secretKey).toString()
}

function decryptData(data) {
  if(data){
    const dataForDecrypt = CryptoJS.AES.decrypt(data, secretKey);
    return JSON.parse(dataForDecrypt.toString(CryptoJS.enc.Utf8));
  }
}
/* END DATA ENCRYPTION AND DECRYPTION PROCESS */

export default createStore({
  state: {

    //LOADER STATE
    isLoading: decryptData(sessionStorage.getItem('loaderShow')),

    //MODAL STATE
    isModalVisible: false,
    selectedMethod: decryptData(sessionStorage.getItem('validationMethod')) || 'sms',
    temporarilySelectedMethod:null,

    /* CLIENT VALIDATION STATE*/
    client_nif: decryptData(Cookies.get('client_nif')),

    was_otp_sent: decryptData(sessionStorage.getItem('was_otp_sent')) || false,
    is_otp_validated: decryptData(sessionStorage.getItem('is_otp_validated')) || false,
    otp: decryptData(Cookies.get('otp')) || null,
  },
  getters: {

    /* MODAL GETTERS */
    isModalVisible: state => state.isModalVisible,
    selectedMethod: state => state.selectedMethod,

  },
  mutations: {

    //LODER MUTATIONS
    SET_LOADER(state, status) {
      state.isLoading = status;
      const encryptedStatus = encryptData(status);
      sessionStorage.setItem('loaderShow', encryptedStatus);
    },

    CLEAR_LOADER(state){
      sessionStorage.removeItem('loaderShow');
      state.isLoading = null;

    },

    //MODAL MUTATIONS
    SHOW_MODAL(state) {
      state.isModalVisible = true;
    },
    HIDE_MODAL(state) {
      state.isModalVisible = false;
    },
    SET_TEMPORARILY_SELECTED_METHOD(state, method) {
      state.temporarilySelectedMethod = method;
    },
    SET_VALIDATION_METHOD(state) {
      state.selectedMethod = state.temporarilySelectedMethod;
      const encryptedMethod = encryptData(state.temporarilySelectedMethod)
      sessionStorage.setItem('validationMethod', encryptedMethod);
    },
    CLEAR_VALIDATION_METHOD(state){
      sessionStorage.removeItem('validationMethod');
      state.selectedMethod = 'sms';
    },

    //CLIENT VALIDATION MUTATIONS
    SET_CLIENT_NIF(state, client_nif) {
      state.client_nif = client_nif;
      const encryptedNif = encryptData(client_nif);
      Cookies.set('client_nif', encryptedNif, { expires: 60 / (24 * 60), path: '/' });
    },

    CLEAR_CLIENT_NIF(state) {
      Cookies.remove('client_nif');
      state.client_nif = null;
    },

    SAVE_OTP_ON_COOKIES(state, otp) {
      state.otp = otp;
      const encryptedOtp = encryptData(otp);
      Cookies.set('otp', encryptedOtp , { expires: 15 / (24 * 60), path: '/' });
      console.log(otp)
    },

    SET_OTP_SENT_STATUS(state) {
      state.was_otp_sent = true;
      const encryptedOtpStatus = encryptData(true);
      sessionStorage.setItem('was_otp_sent', encryptedOtpStatus)
    },

    CLEAR_OTP_SENT_STATUS(state) {
      state.was_otp_sent = false;
      sessionStorage.removeItem('was_otp_sent');

    },

    SET_OTP_STATUS(state, status) {
      state.is_otp_validated = status
      const encryptedOtpStatus = encryptData(status);
      sessionStorage.setItem('is_otp_validated', encryptedOtpStatus)
    },

    CLEAR_OTP_STATUS(state) {
      state.is_otp_validated = false;
      sessionStorage.removeItem('is_otp_validated');
    }

  },
  actions: {
    //LOADER ACTIONS
    showLoader({commit}, payload) {
      commit('SET_LOADER', payload);
    },
    hideLoader({commit}, payload) {
      commit('SET_LOADER', payload);
    },
    clearLoader({commit}) {
      commit('CLEAR_LOADER');
    },

    //MODAL AND SELECTECD METHOD ACTIONS
    showModal({commit}) {
      commit('SHOW_MODAL');
    },
    hideModal({commit}) {
      commit('HIDE_MODAL');
    },
    setTeporarilySelectedMethod({commit}, method){
      commit('SET_TEMPORARILY_SELECTED_METHOD', method);
    },
    setValidationMethod({commit}) {
        commit('SET_VALIDATION_METHOD');
    },
    clearValidationMethod({commit}) {
      commit('CLEAR_VALIDATION_METHOD');
    },

    /* CLIENT VALIDATION ACTIONS */
    setClientNif({commit}, client_nif) {
      commit('SET_CLIENT_NIF', client_nif);
    },
    clearClientNif({commit}) {
      commit('CLEAR_CLIENT_NIF');
    },

    //DEBTOR DEBTS ACTIONS
    saveOtpOnCookies({commit}, otp) {
      commit('SAVE_OTP_ON_COOKIES', otp);
    },

    setOtpSentStatus({commit}) {
      commit('SET_OTP_SENT_STATUS')
    },

    clearOtpSentStatus({commit}) {
      commit('CLEAR_OTP_SENT_STATUS');
    },

    setOtpStatus({commit}, status) {
      commit('SET_OTP_STATUS', status);
    },

    clearOtpStatus({commit}) {
      commit('CLEAR_OTP_STATUS');
    }


  },
  modules: {
  /*   loader */
  }
})
