<template>
    <div class="footer-container">
        <footer class="footer d-flex align-items-center">
            <div class="footer-logo-container">
                <router-link to="/">
                    <img :src="footerlogo" alt="Logotipo Regulariza" class="footer-img">
                </router-link>
            </div>
            <div class="ul-container">
                <ul class="navbar mb-0">
                    <li class="nav-item">
                        <router-link class="nav-link" to="/howitworks">Como funciona</router-link>
                    </li>
                    <li class="nav-item">
                        <router-link class="nav-link" to="/protection-policies">Políticas de proteção de dados</router-link>
                    </li>
                    <li class="nav-item">
                        <router-link class="nav-link" to="/contact">Contacto</router-link>
                    </li>
                </ul>
            </div>
            <div class="social-container">
                <i class="bi bi-facebook"></i>
                <i class="bi bi-instagram"></i>
                <i class="bi bi-linkedin"></i>
            </div>
        </footer>
        <div class="copyright pb-4">
            <span>&copy; Positiva Fintech - 2024</span>
        </div>
    </div>
    
</template>

<script>

export default {
    name: 'FooterView',
    props: ['footerlogo']

}

</script>

<style scoped>

.footer-container{
    width: 100%;
    height: 250px;
    display: flex;
    flex-direction: column
}
.footer{
    background-color: #212121;
    width: 100%;
    height: 100%;
    padding: 30px 300px;
    justify-content: space-between;
}

.footer-logo-container{
    display: flex;
    justify-content: center
}


.footer-img{
    width: 120px;
    filter: invert(0.63);
}

.navbar{
    display: flex;
    gap: 3rem;
}

.nav-link{
    color: #666666;
    transition: all 0.5s;
    font-size: 15px;
}

.nav-link:hover{
    color: #ffffff;
}

.router-link-active{
    color: #ffffff !important;
}
.social-container{
    display: flex;
    gap: 2rem;
}

.bi{
    color: #666666;
    font-size: 20px;
    cursor: pointer;
    transition: all 0.5s;
}

.bi:hover{
    color: #ffffff;
}

.copyright {
    width: 100%;
    background-color: #212121;
    color: #666666;
    font-size: 15px;
    text-align: center;
}

@media (max-width:1910px) {
    .footer{
        padding: 30px 60px;
    }
}

@media (max-width:992px) {
    .navbar{
        flex-direction: column;
        align-items: start;
        gap: .9rem;
    }
}

@media (max-width:576px) {
    .footer-logo-container,
    .ul-container,
    .social-container{
        align-self: first baseline;
    }

    .footer-logo-container{
        margin-top: 2rem;
    }
    .footer{
        padding: 30px 15px;
        flex-direction: column;
        justify-content: center;
        gap: 1rem;
        
    }
    .copyright{
        padding-top: 1rem;
    }
}

</style>