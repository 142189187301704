<template>
  <div class="home d-flex">
    <div class="row home-container-components d-flex">
      <div class="col-lg-6 align-self-center">
        <CheckCitizenIdView/>
      </div>
      <div class="hero-image-container col-lg-6 align-self-center">
        <HomeImageView :hero="home_image_src"/>
      </div>
    </div>
  </div>
</template>

<script>

import CheckCitizenIdView from '../components/start/CheckCitizenIdView.vue';
import HomeImageView from '../components/start/HomeImageView.vue'

export default {
  name: 'HomeView',
  components: {
    CheckCitizenIdView,
    HomeImageView
  },
  data(){
    return{
      home_image_src: 'img/hero.png',
    }
  }
}
</script>

<style scoped>
  .home{
    min-height: 90vh;
    height: auto;
    padding: 20px 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 3rem;
  }

  .home-container-components{
    height: 100%;
  }

@media (max-width:1910px) {
  .home{
      padding: 20px 60px;
  }
}

@media (max-width:992px) {
  .home{
    min-height: 80vh;
  }
  .hero-image-container{
    /* margin-top: 1.5rem; */
    display: none;
  }
}


@media (max-width:576px) {
  .home{
      padding: 10px 15px;
  }
}
</style>
