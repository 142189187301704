<template>
    <div>
        <div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasRight" aria-labelledby="offcanvasRightLabel">
        <div class="offcanvas-header">
            <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
        </div>
        <div class="offcanvas-body">
            <ul class="d-flex navigation-items flex-column p-0">
                    <li class="nav-item mx-3">
                        <router-link class="nav-link" to="/" exact-active-class="active">Início</router-link>
                    </li>
                    <li class="nav-item mx-3">
                        <router-link class="nav-link" to="/about">Sobre</router-link>
                    </li>
                    <li class="nav-item mx-3">
                        <router-link class="nav-link" to="/howitworks">Como funciona</router-link>
                    </li>
                    <li class="nav-item mx-3">
                        <router-link class="nav-link" to="/contact">Contacto</router-link>
                    </li>
                </ul>
        </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'MobileMenuView',
    data(){
        return {

        }
    },
}

</script>

<style scoped>

.btn-close:focus{
    box-shadow: none;
}
.navigation-items{
    gap: 2rem;
}

.router-link-active{
    color: #1ECAD4 !important;
}

.nav-item{
    border-bottom: 1px solid #66666635;
    padding-bottom: 1rem;
}

.nav-link{
    color: #666666;
}

</style>