<template>
  <div>
    <Navigation-Bar-View :logo="logo_src"/>
    <router-view/>
    <Footer-View :footerlogo="footer_logo_src"/>
  </div>
</template>



<script>

import FooterView from './components/layout/FooterView.vue';
import NavigationBarView from "./components/layout/NavigationBarView.vue";


export default {
  name: 'App',
  components: {
    NavigationBarView,
    FooterView
  },

  data () {
    return {
      logo_src: 'img/logo.png',
      footer_logo_src: 'img/positiva logo.png',
    }
  }
}

</script>


<style>

/* Font family */

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');


/****Reset CSS ****/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
  font-family: "Poppins", sans-serif;
}

a{
  text-decoration: none
}
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

</style>
