<template>
    <div class="hero-container d-flex justify-content-end">
        <img :src="hero" alt="imagem de teste" class="hero-img">
    </div>
</template>

<script>

export default {
    name: 'HomeImageView',
    props: ['hero'],
    data () {
    return {
        
    }
  }
}


</script>


<style scoped>

.hero-container{
    width: 100%;
}

.hero-img{
    width: 480px;
}


@media (max-width:1200px) {
    .hero-img{
        width: 380px;
    }
}

@media (max-width:992px) {
    .hero-container{
        flex-direction: column;
        align-items: center;
    }
}
</style>