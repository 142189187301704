import axios from "axios";

const api = axios.create({
    baseURL: 'https://dev.positiva.co.ao/api/',
   headers: {
        'Content-Type':'application/json',
        'Accept':'application/json',
        'X-Content-Type-Options': 'nosniff',
        'X-Frame-Options': 'deny_all_fakers',
        'X-XSS-Protection': '1; mode=block',

    },
});


export default api; 