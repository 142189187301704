import { createRouter, createWebHistory } from 'vue-router';
import HomeView from '../views/HomeView.vue';
import store from '@/store';

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('../views/AboutView.vue')
  },
  {
    path: '/howitworks',
    name: 'howitworks',
    component: () => import('../views/HowItWorksView.vue')
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import('../views/ContactView.vue')
  }
  ,
  {
    path: '/protection-policies',
    name: 'dataprotectionpolicies',
    component: () => import('../views/DataProtectionPoliciesView.vue')
  },
  {
    path: '/validation',
    name: 'validation',
    component: () => import('../views/ValidationView.vue'),
    beforeEnter: (to, from, next) => {
      if(store.state.client_nif) next();
      else next('/');
    }
  },
  {
    path: '/debts',
    name: 'debts',
    component: () => import('../views/DebtsView.vue'),
    beforeEnter: (to, from, next) => {
      if(store.state.was_otp_sent) next();
      else next('/');
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})


export default router
