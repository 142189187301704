<template>
    <div>
        <nav class="navbar justify-content-between align-items-center">
            <router-link to="/" class="logo_url">
                <img :src="logo" alt="Logotipo Regulariza" class="logo">
            </router-link>
            <ul class="d-flex navigation-items">
                <li class="nav-item mx-3">
                    <router-link class="nav-link" to="/" exact-active-class="active">Início</router-link>
                </li>
                <li class="nav-item mx-3">
                    <router-link class="nav-link" to="/about">Sobre</router-link>
                </li>
                <li class="nav-item mx-3">
                    <router-link class="nav-link" to="/howitworks">Como funciona</router-link>
                </li>
                <li class="nav-item mx-3">
                    <router-link class="nav-link" to="/contact">Contacto</router-link>
                </li>
            </ul>
            <i class="bi bi-list" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight"></i>
        </nav>
        <MobileMenuView/>
    </div>
    
</template>

<script>

import MobileMenuView from '../complement/MobileMenuView.vue';


export default {
    name: 'NavigationBarView',
    components: {
        MobileMenuView
    },
    props: ['logo']

}

</script>

<style scoped>

.navbar{
    box-shadow: 0px 15px 13px -16px rgba(0,0,0,0.1);
    transition: all 0.5s;
    padding: 10px 300px;
    align-items: center;
}

.logo{
    width: 75px;
}

.navigation-items{
    margin-top: 10px;
    transition: all 0.5s;
}

.nav-link{
    color: #666666;
}

.nav-link:hover{
    color: #1ECAD4;
}
.router-link-active{
    color: #1ECAD4 !important;
}

.bi-list{
    font-size: 2.5rem;
    display: none;
    cursor: pointer;
}

@media (max-width:1910px) {
    .navbar{
        padding: 10px 60px;
    }
}


@media (max-width:992px) {
    .navbar{
        padding: 10px 15px;
    }

    .navigation-items{
        display: none !important;
    }

    .bi-list{
        display: block;
    }
}

</style>